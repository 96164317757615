<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">Hoàn thành nhập kho</v-card-title>
      <v-card-text>Quét Tracking đơn hàng</v-card-text>

      <v-card-text>
        <div class="mb-3">
          <v-text-field
            v-model="tracking_id"
            class="c-input-small"
            type="text"
            :label="$t('labels.tracking')"
            autofocus
            dense
            outlined
            clearable
            hide-details
            @keyup.enter="confirm"
          ></v-text-field>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t("labels.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ReceiptFinishSession",
  components: {},
  props: {},
  data: () => ({
    isLoading: false,
    tracking_id: null,
  }),
  computed: {
    isDisabledBtn() {
      return !this.tracking_id;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    cancelRoll() {
      this.rollDialog = false;
    },
    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/receipt-confirm-transfer-finish", {
          tracking_id: this.tracking_id,
        });
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.cancel();
      } catch (e) {
        this.tracking_id = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
  },
};
</script>

<style scoped></style>
